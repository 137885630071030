import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Nothing yet here, just a test, back to my CV to see more about me.
        </p>
        <a
          className="App-link"
          href="https://t.me/rurimeiko"
          target="_blank"
          rel="noopener noreferrer"
        >
          Telegram
        </a>
        <a
          className="App-link"
          href="/Nguyen Tran Hoang Long - CV.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          My CV
        </a>
      </header>
    </div>
  );
}

export default App;
